<template>
  <div class="howto">
    <div class="howto__title">{{$t('howto.title')}}</div>
    <div class="steps">
      <div class="steps__title">{{$t('howto.steps')}}</div>
      <div class="grid grid--no-gutter">
        <div class="grid__col-md-3">
          <div class="steps__cell">
            <div class="steps__number">1</div>
          </div>
          <div class="steps__cell">
            <p class="steps__text steps__text--padded" v-html="$t('howto.step1')"></p>
          </div>
        </div>
        <div class="grid__col-md-5 grid__col-left">
          <div class="steps__cell">
            <div class="steps__number">2</div>
          </div>
          <div class="steps__cell">
            <p class="steps__text">{{$t('howto.step2_list')}}</p>
            <ul class="steps__list">
              <li class="steps__text">{{$t('howto.step2_list_item1')}}</li>
              <li class="steps__text">{{$t('howto.step2_list_item2')}}</li>
            </ul>
          </div>
        </div>
        <div class="grid__col-md-3 grid__col-left">
          <div class="steps__cell">
            <div class="steps__number">3</div>
          </div>
          <div class="steps__cell">
            <p class="steps__text steps__text--padded" v-html="$t('howto.step3')"></p>
          </div>
        </div>
      </div>
      <div class="grid grid--no-gutter grid--padded">
        <div class="grid__col-md-3">
          <div class="steps__cell">
            <div class="steps__number">4</div>
          </div>
          <div class="steps__cell">
            <p class="steps__text">{{$t('howto.step4')}}</p>
          </div>
        </div>
        <div class="grid__col-md-2 grid__col-left">
          <div class="steps__cell">
            <div class="steps__number">5</div>
          </div>
          <div class="steps__cell">
            <p class="steps__text">{{$t('howto.step5')}}</p>
          </div>
        </div>
        <div class="grid__col-md-3">
          <div class="steps__cell">
            <div class="steps__number">6</div>
          </div>
          <div class="steps__cell">
            <p class="steps__text steps__text--padded">{{$t('howto.step6')}}</p>
          </div>
        </div>
        <div class="grid__col-md-4 grid__col-left">
          <div class="steps__cell">
            <div class="steps__number">7</div>
          </div>
          <div class="steps__cell">
            <p class="steps__text" v-html="$t('howto.step7')"></p>
          </div>
        </div>
      </div>
      <div class="howto__footer">
        <div class="howto__info">
          <span class="icon icon--shield"></span>
          <p class="text">{{$t('howto.secure')}}</p>
        </div>
        <div class="nav nav--right">
          <v-link href="/" class="nav__button nav__button--inverted">Go back</v-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export const messages = {
    en: {
      howto: {
        title: 'How to become a biobank participant? The Estonian example',
        steps: 'Steps to take to become a Biobank participant:',
        step1: `
        Information and registration online <a href="https://geenidoonor.ee/">www.geenidoonor.ee</a>
        `,
        step2_list: `Citizens identification`,
        step2_list_item1: `Electronic ID (ID Card / Mobile ID) required`,
        step2_list_item2: `Digitally signed consent form`,
        step3: `Health data obtained by linking from<br>the e-Health system`,
        step4: `Blood sample (6ml)`,
        step5: `Genome-wide genotyping by microarrays`,
        step6: `Development and validation of genetic and total risk models`,
        step7: `
        Risk predictions to e-Health<br>to be further used by the biobank<br>participants and medical doctors
          `,
        secure: `
        Estonian health information is kept completely secure and at the same time accessible to authorised individuals. The system uses KSI Blockchain technology to ensure data integrity and mitigate internal threats to the data.
        `
      }
    }
  };

  export default {
    i18n: { messages },
    beforeCreate: function() {
        document.body.className = 'howTo-page';
    }
  };
</script>
