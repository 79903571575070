const pnorm = (z) => {
  // function pnorm(z) {
  // Algorithm AS66 Applied Statistics (1973) vol22 no.3
  // Computes P(Z<z)
  // z=parseFloat(z);
  // upper=(form.upper[0].checked ? true:false);
  let upper = (false);
  let ltone = 7.0;
  let utzero = 18.66;
  let con = 1.28;
  let a1 = 0.398942280444;
  let a2 = 0.399903438504;
  let a3 = 5.75885480458;
  let a4 = 29.8213557808;
  let a5 = 2.62433121679;
  let a6 = 48.6959930692;
  let a7 = 5.92885724438;
  let b1 = 0.398942280385;
  let b2 = 3.8052e-8;
  let b3 = 1.00000615302;
  let b4 = 3.98064794e-4;
  let b5 = 1.986153813664;
  let b6 = 0.151679116635;
  let b7 = 5.29330324926;
  let b8 = 4.8385912808;
  let b9 = 15.1508972451;
  let b10 = 0.742380924027;
  let b11 = 30.789933034;
  let b12 = 3.99019417011;
  let alnorm, y;

  if (z < 0) {
    upper = !upper;
    z = -z;
  }
  // eslint-disable-next-line no-mixed-operators
  if (z <= ltone || upper && z <= utzero) {
    y = 0.5 * z * z;
    if (z > con) {
      alnorm = b1 * Math.exp(-y) / (z - b2 + b3 / (z + b4 + b5 / (z - b6 + b7 / (z + b8 - b9 / (z + b10 + b11 / (z + b12))))));
    } else {
      alnorm = 0.5 - z * (a1 - a2 * y / (y + a3 - a4 / (y + a5 + a6 / (y + a7))));
    }
  } else {
    alnorm = 0;
  }
  if (!upper) alnorm = 1 - alnorm;
  return (alnorm);
  // }
};

const qnorm = (p) => {
  // ALGORITHM AS 111, APPL.STATIST., VOL.26, 118-121, 1977.
  // Computes z = invNorm(p)

  p = parseFloat(p);
  const split = 0.42;

  const a0 = 2.50662823884;
  const a1 = -18.61500062529;
  const a2 = 41.39119773534;
  const a3 = -25.44106049637;
  const b1 = -8.47351093090;
  const b2 = 23.08336743743;
  const b3 = -21.06224101826;
  const b4 = 3.13082909833;
  const c0 = -2.78718931138;
  const c1 = -2.29796479134;
  const c2 = 4.85014127135;
  const c3 = 2.32121276858;
  const d1 = 3.54388924762;
  const d2 = 1.63706781897;

  const q = p - 0.5;

  let r, ppnd;

  if (Math.abs(q) <= split) {
    r = q * q;
    ppnd = q * (((a3 * r + a2) * r + a1) * r + a0) / ((((b4 * r + b3) * r + b2) * r + b1) * r + 1);
  } else {
    r = p;
    if (q > 0) r = 1 - p;
    if (r > 0) {
      r = Math.sqrt(-Math.log(r));
      ppnd = (((c3 * r + c2) * r + c1) * r + c0) / ((d2 * r + d1) * r + 1);
      if (q < 0) ppnd = -ppnd;
    }
    else {
      ppnd = 0;
    }
  }

  return ppnd;
};

const basesurv = `
25 0.9999 0.9987
30 0.9988 0.9984
35 0.9971 0.9962
40 0.9956 0.9915
45 0.9937 0.9868
50 0.9907 0.9784
55 0.9868 0.965
60 0.9816 0.9505
65 0.9757 0.9315
70 0.97 0.9123
75 0.9646 0.8941
`.trim()
  .split('\n')
  .reduce((t, i) => {
    const [age, s0n, s0m] = i.split(' ');
    t['F'].base[age] = +s0n;
    t['M'].base[age] = +s0m;
    return t;
  }, {
    'M': {
      base: {},
    },
    'F': {
      base: {},
    },
  });

const bmiGroupRegex = /bmig\((.+),(.+)]"/;
const whGroupRegex = /vpg\((.+),(.+)]"/;

const mkaal = `
"mbmig(27.5,30]" 0.43
"mbmig(30,35]" 0.96
"mbmig(35,40]" 1.29
"mbmig(40,Inf]" 2.06
"mvpg(0.5,0.6]" 0.53
"mvpg(0.6,0.75]" 0.77
"mvpg(0.75,1]" 1.23
"phyp" 0.61
"pinfarkt" 0.56
`;

const nkaal = `
"nbmig(25,27.5]" 0.62
"nbmig(27.5,32.5]" 0.88
"nbmig(32.5,40]" 1.28
"nbmig(40,Inf]" 1.75
"nvpg(0.45,0.5]" 0.83
"nvpg(0.5,0.6]" 1.14
"nvpg(0.6,0.7]" 1.51
"nvpg(0.7,1]" 1.71
"phyp" 0.59
"pinfarkt" 0.6
`;

const processParam = (gender, data) => {
  return data.trim()
    .split('\n')
    .reduce((t, i) => {
      const [param, value] = i.split(' ');
      if (param.includes('bmig')) {
        const match = bmiGroupRegex.exec(param);
        const start = match[1];
        const end = match[2] === 'Inf' ? Number.MAX_VALUE : +match[2];
        if (t[gender].bmi.length === 0) {
          t[gender].bmi.push([Number.MIN_VALUE, +start, 0]);
        }
        t[gender].bmi.push([+start, end, +value]);
      }
      if (param.includes('vpg')) {
        const match = whGroupRegex.exec(param);
        const start = match[1];
        const end = match[2] === 'Inf' ? Number.MAX_VALUE : +match[2];
        if (t[gender].whr.length === 0) {
          t[gender].whr.push([Number.MIN_VALUE, +start, 0]);
        }
        t[gender].whr.push([+start, end, +value]);
      }
      if (param.startsWith('"phyp')) {
        t[gender].hyp = +value;
      }
      if (param.startsWith('"pinfarkt')) {
        t[gender].mi = +value;
      }
      return t;
    }, {
      [gender]: {
        bmi: [],
        whr: [],
        hyp: 0,
        mi: 0,
      },
    });
};

const params = Object.assign({}, processParam('M', mkaal), processParam('F', nkaal));
params['F'].base = basesurv.F.base;
params['M'].base = basesurv.M.base;

const bmiFn = (weight, height) => 10000 * weight / Math.pow(height, 2);
const whrFn = (waist, height) => waist / height;

function between(x, min, max) {
  return x >= min && x <= max;
}

const bmiCoefFn = (gender, bmi) => {
  // console.log('bmiCoefFn', bmi);
  const idx = params[gender].bmi.findIndex(i => between(bmi, i[0], i[1]));
  // console.log('bmiIdx', idx);
  return params[gender].bmi[idx][2];
};

const whrCoefFn = (gender, whr) => {
  // console.log('whr', whr);
  // console.log('whrParam', params[gender].whr);
  const idx = params[gender].whr.findIndex(i => between(whr, i[0], i[1]));
  // console.log('whrIdx', idx);
  return params[gender].whr[idx][2];
};

const hypCoefFn = (gender, hasHyp) => {
  return hasHyp ? params[gender].hyp : 0;
};

const miCoefFn = (gender, hasMi) => {
  return hasMi ? params[gender].mi : 0;
};

const baseFn = (gender, age) => {
  const ageStart = Math.max(5 * Math.floor(age / 5), 25);
  const ageEnd = Math.max(5 * Math.ceil(age / 5), 25);

  let currentAgeP = 1;

  if (ageStart === ageEnd) {
    // console.log("eq");
    currentAgeP = params[gender].base[ageStart];
  } else {
    const startP = params[gender].base[ageStart];
    const endP = params[gender].base[ageEnd];
    // console.log("not eq")
    // console.log(`startP:${startP}, endP:${endP}`);
    currentAgeP = startP + (age - ageStart) * ((endP - startP) / (ageEnd - ageStart));
  }

  // console.log(`baseFn start:${ageStart}, end:${ageEnd}, currentAgeP:${currentAgeP}`);
  return currentAgeP;
};

const baseCoefFn = (gender, age, refAge) => {
  if (age === refAge) {
    return 1;
  }
  let currentAgeP = baseFn(gender, age);
  let refAgeP = baseFn(gender, refAge);
  return refAgeP / currentAgeP;
};

const combinedRiskFn = (baseRiskCoef, erisk, grs) => {
  const res = 1 - Math.pow(baseRiskCoef, Math.exp(erisk + grs * 0.3));
  // console.log({baseRiskCoef, erisk, grs, res});
  return +((res * 100).toFixed(2));
};

const grsGroups = () => {
  let array = [Number.MIN_VALUE];
  for (let i = 0.1; i <= 0.9; i += 0.1) {
    array.push(qnorm(i))
  }
  array.push(Number.MAX_VALUE);
  return array;
};

const grsGroupFromGrs = (grs) => {
  return grsGroups().findIndex(i => i > 1) + 1
};

const seq = (start, end, step) => {
  const res = [];
  console.log(`start:${start}, end:${end}, mod:${start % step}`);
  if (start % step !== 0) {
    res.push(start);
  }
  for (let i = start; i <= end; i += step) {
    res.push(i);
  }
  return res;
};

const graphBmiGroups = (gender) => {
  if (gender === 'M') {
    return [21, 28.5, 31, 36, 46].map(i => i + 0.5);
  }
  if (gender === 'F') {
    return [21, 26, 28.5, 36, 45].map(i => i + 0.5);
  }
  throw new Error(`Unknown gender ${gender}`);
};

const graphWhrGroups = (gender) => {
  if (gender === 'M') {
    return [0.44, 0.53, 0.56, 0.63, 0.76].map(i => i + 0.005);
  }
  if (gender === 'F') {
    return [0.43, 0.49, 0.53, 0.63, 0.72].map(i => i + 0.005);
  }
  throw new Error(`Unknown gender ${gender}`);
};

const graphLabels = (gender, height) => {
  let bmi = null;
  if (gender === 'M') {
    bmi = [27.5, 30, 35, 40];
  }
  else if (gender === 'F') {
    bmi = [25, 27.5, 32.5, 40];
  } else {
    throw new Error(`Unknown gender: ${gender}`)
  }
  const labels = [];
  for (let i = 0; i < bmi.length; i += 1) {
    const weight = Math.round(weightFromBmi(height, bmi[i]));
    const r = {
      weight,
    };
    if (i === 0) {
      r.label = `< ${weight}kg`;
    } else if (i !== bmi.length) {
      r.label = `${labels[i - 1].weight} - ${weight}kg`;
    } else {
      r.label = `${weight}kg >`;
    }
    labels.push(r);
  }
  labels[labels.length] = {label: labels[labels.length - 1].weight + "kg >"};

  return labels.map(i => ({label: i.label}));
};

const weightFromBmi = (height, bmi) => {
  return (bmi * Math.pow(height, 2)) / 10000;
};

const waistFromWhr = (height, whr) => {
  return whr * height;
};

const grsRiskText = (grsRisk) => {
  if (grsRisk >= 1 && grsRisk <= 2) {
    return 'low';
  }
  if (grsRisk >= 3 && grsRisk <= 8) {
    return 'heightened';
  }
  if (grsRisk >= 9 && grsRisk <= 10) {
    return 'high'
  }
};

const envRiskText = (totalRisk) => {
  if (totalRisk <= 1) {
    return 'low';
  } else if (totalRisk > 1 && totalRisk <= 2) {
    return 'heightened';
  } else if (totalRisk > 2 && totalRisk <= 3) {
    return 'high';
  } else {
    return 'very high';
  }
};

const risk = (gender, age, weight, height, waist, hasHyp, hasMi, grs) => {
  const bmi = bmiFn(weight, height);
  //console.log("bmi:", bmi);
  const whr = whrFn(waist, height);
  //console.log("whr:", whr);
  const bmiCoef = bmiCoefFn(gender, bmi);
  //console.log("bmiCoef:", bmiCoef);
  const whrCoef = whrCoefFn(gender, whr);
  //console.log("whrCoef:", whrCoef);
  const hypCoef = hypCoefFn(gender, hasHyp);
  //console.log('hypCoef:', hypCoef);
  const miCoef = miCoefFn(gender, hasMi);

  const erisk = bmiCoef + whrCoef + hypCoef + miCoef;
  //console.log("erisk:", erisk);

  const grsRisk = pnorm(grs);
  const grsGroup = Math.ceil(grsRisk * 10);

  let res = {
    input: {
      gender,
      age,
      weight,
      height,
      waist,
      hasHyp,
      hasMi,
      grs,
    },
    bmi,
    whr,
    envRisk: erisk,
    envText: envRiskText(Math.max(0, erisk + (grs * 0.3)) - (gender === 'F' ? 0.5 : 0)),
    grsGroup: grsGroup,
    grsText: grsRiskText(grsGroup),
  };

  if (age >= 70) {
    return res;
  }
  if (age >= 18 && age < 70) {
    const startYear = Math.max(Math.ceil(age / 5) * 5, 25);
    const years = seq(startYear, 75, 5);
    const bmiValues = graphBmiGroups(gender);
    const whrValues = graphWhrGroups(gender);
    const labels = graphLabels(gender, height);

    const graph = years.reduce((total, refAge, yearIdx) => {
      // console.log(`${yearIdx} year: `, refAge);
      const baseRiskCoef = baseCoefFn(gender, age, refAge);
      total.lines[0].push(combinedRiskFn(baseRiskCoef, erisk, grs));

      for (let idx = 1; idx < 6; idx += 1) {
        const bmi = bmiValues[idx - 1];
        const whr = whrValues[idx - 1];

        if (!total.lines[idx]) {
          total.lines[idx] = startYear === age ? [] : [0];
          total.lineDesc[idx] = {
            ...labels[idx - 1],
          };
        }
        total.lines[idx].push(
          combinedRiskFn(
            baseRiskCoef,
            bmiCoefFn(gender, bmi) + whrCoefFn(gender, whr) + hypCoef + miCoef,
            grs,
          ),
        );
      }

      return total;
    }, {
      lines: startYear === age ? [[]] : [[0]],
      years: startYear === age ? years : [age, ...years],
      lineDesc: {
        0: {label: 'Current', bmi, whr, weight, waist, height},
      }
    });

    Object.assign(res, {
      bmiCoef,
      whrCoef,
      hypCoef,
      miCoef,
      totalRisk10yP: combinedRiskFn(baseCoefFn(gender, age, age + 10), erisk, grs),
      totalRisk70P: combinedRiskFn(baseCoefFn(gender, age, 70), erisk, grs),
      graph: graph,
    });

    return res;
  }
};

// For testing
// const res1 = risk('M', 59, 77, 181, 82.6, false, false, 1);

// Add some functions to export for partial import
risk.riskFn = risk;
risk.baseFn = baseFn;
risk.baseCoefFn = baseCoefFn;
risk.combinedRiskFn = combinedRiskFn;
risk.pnorm = pnorm;

module.exports = risk;
