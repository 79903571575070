<template>
    <div class="info info--landing">
        <div class="landing__title">You can find all the information here and get the contacts of experts.</div>
        <div class="landing__content">
            <div class="illustration"></div>
            <div class="landing__links">
                <div class="landing__link">
                    <div class="icon"></div>
                    <div class="text">Smart living environment</div>
                </div>
                <div class="landing__link">
                    <div class="icon"></div>
                    <div class="text">Cybersecurity</div>
                </div>
                <div class="landing__link">
                    <div class="icon"></div>
                    <div class="text">Personalised medicine</div>
                </div>
            </div>
        </div>
        <div class="landing__footer">
            <div class="landing__footer-items">
                <div class="grid grid--no-gutter">
                  <div class="grid__col-md-5">
                    <div class="qr">
                        <div class="qr__img"></div>
                        <div class="qr__text">You can find all the information here and get the contacts of experts.</div>
                        <div class="qr__link">http://bit.ly/esof2018</div>
                    </div>
                  </div>
                  <div class="grid__col-md-3">
                    <div class="logo logo--footer">
                        <img class="logo__img" src="../../static/logos/RIE_logo.png" alt="RIE logo">
                    </div>
                  </div>
                  <div class="grid__col-md-1 content-center">
                    <div class="partner-logo partner-logo--est"></div>
                  </div>
                  <div class="grid__col-md-2 content-center">
                    <div class="partner-logo partner-logo--etag"></div>
                  </div>
                  <div class="grid__col-md-1 content-center">
                    <div class="partner-logo partner-logo--eu"></div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    beforeCreate: function() {
      document.body.className = 'smartest-landing-page';
    }
  };
</script>
