<template>
  <div class="landing">
    <div class="logo">
      <img class="logo__img" src="../../static/logos/RIE_logo.png" alt="RIE logo">
      </img>
    </div>
    <div class="landing__title">{{$t('landing.title')}}</div>
    <div class="nav">
      <v-link href="/app" class="nav__button nav__button--landing">
        {{$t('landing.linkToApp')}}
      </v-link>
    </div>
    <div class="landing__description">
      <p class="text text--tight">{{$t('landing.introduction')}}</p>
      <p class="text text--tight">{{$t('landing.aim')}}</p>
    </div>
    <div class="landing__footer">
      <div class="nav nav--inline">
        <v-link href="/howTo" class="nav__button nav__button--inverted nav__button--landing-alt">
            {{$t('landing.linkToGuide')}}
        </v-link>
      </div>
      <div class="landing__footer-items">
        <div class="grid grid--no-gutter">
          <div class="grid__col-md-3">
            <div class="partner-logo partner-logo--est"></div>
          </div>
          <div class="grid__col-md-6">
            <div class="partner-logo partner-logo--etag"></div>
          </div>
          <div class="grid__col-md-3">
            <div class="partner-logo partner-logo--eu"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export const messages = {
    en: {
      landing: {
        title: 'How do genetics impact YOUR health?',
        introduction: `
          The Estonian biobank is a population-based longitudinal biobank that represents about 5% of Estonia’s adult population (52,000 participants). Participant can sign up for feedback and counselling on individual genomic results. 100,000 new biobank participants will be recruited and genotyped as part of National Personalized Medicine programme during 2018.
        `,
        aim: ` Estonia aims to build a healthcare system that’s personalized and more effective.`,
        linkToGuide: `How to become a biobank participant?`,
        linkToApp: `Check it out!`,
      }
    }
  };

  export default {
    i18n: {messages},
    beforeCreate: function() {
        document.body.className = 'landing-page';
    }
  };
</script>
