import App from './pages/App.vue';
import LandingPage from './pages/Landing.vue';
import HowToPage from './pages/HowTo.vue';

import SmartEstLandingPage from './pages/SmartEst_Landing.vue';
import SmartEstLivingPage from './pages/SmartEst_Living.vue';
import SmartEstMedPage from './pages/SmartEst_Medicine.vue';
import SmartEstSecurityPage from './pages/SmartEst_Security.vue';

export default {
  '/': LandingPage,
  '/howTo': HowToPage,
  '/app': App,
  '/smartest/landing': SmartEstLandingPage,
  '/smartest/living': SmartEstLivingPage,
  '/smartest/medicine': SmartEstMedPage,
  '/smartest/security': SmartEstSecurityPage
};
