<template>
  <svg :width="width" :height="height">

    <g v-for="(_ , idx) in Array(10)" :transform="circleTransform(idx)" :key="idx">
      <man :opacity="opacity(idx)" :color="circleColor(idx)"></man>
      <!--<circle r="40" :style="{fill: color(idx), rx: 40}" v-if="idx === group - 1"></circle>-->
    </g>

    <circle r="40" :style="{fill: color(group - 1), rx: 40}" :transform="circleTransform(group - 1)"></circle>

    <text class="text text--alt" style="text-anchor:middle" x="110" y="40">
      {{smallerThanText[0]}}
    </text>
    <text class="text text--alt" style="text-anchor:middle" x="110" y="60">
      {{smallerThanText[1]}}
    </text>
    <text class="text text--alt" style="text-anchor:middle" x="840" y="40">
      {{largerThanText[0]}}
    </text>
    <text class="text text--alt" style="text-anchor:middle" x="840" y="60">
      {{largerThanText[1]}}
    </text>
  </svg>
</template>

<script>
  import CircleGraph from './GeneticRiskGraphCircles.vue';

  const NUMBERS = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];

  const messages = {
    en: {
      geneticRiskGraph: {
        numbers: NUMBERS,
        outOf: 'people out of 10 have',
        smallerThan: `lower inherited risk than you.`,
        largerThan: `higher inherited risk than you.`,
      },
    },
  };

  export default {
    components: {
      'man': CircleGraph,
    },
    name: 'genetic-risk-graph',
    props: {
      group: {
        type: Number,
        default: 8,
        validator(value) {
          return value > 0 && value <= 10;
        },
      },
    },
    data() {
      return {
        width: 1000,
        height: 90,
      };
    },
    i18n: {messages},
    computed: {
      smallerThanText() {
        const vm = this;

        if (this.group === 1) {
          return ['', ''];
        }
        return [`${vm.$t('geneticRiskGraph.numbers')[this.group - 2]} ${vm.$t('geneticRiskGraph.outOf')}`, vm.$t('geneticRiskGraph.smallerThan')];
      },
      largerThanText() {
        const vm = this;
        if (this.group === 10) {
          return '';
        }
        return [`${vm.$t('geneticRiskGraph.numbers')[10 - this.group - 1]} ${vm.$t('geneticRiskGraph.outOf')}`, vm.$t('geneticRiskGraph.largerThan')];
      },
    },
    methods: {
      circleTransform(idx) {
        const {width} = this,
          circleWidth = 40,
          circleSpacing = 10,
          offset = 250,
          multiplier = idx * (circleWidth + circleSpacing),
          scaleX = offset + multiplier;

        return `translate(${scaleX}, 45)`;
      },
      circleColor(idx) {
        return '#0000f0';
      },
      color(idx) {
        return '#ff4800';
      },
      opacity(idx) {
        const opacity = 0.1 + idx * 0.1;
        return opacity;
      },
    },
  };
</script>
