<template>
    <div>
        <h3>404</h3>
        <v-link href="/">home</v-link>
        <v-link href="/app">app</v-link>
        <v-link href="/howTo">howTo</v-link>
    </div>
</template>

<script>
  export default {
    beforeCreate: function() {
      document.body.className = 'error-page';
    }
  };
</script>
