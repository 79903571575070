<template>
  <g >
    <circle :style="style" r="30" />
  </g>
</template>

<script>
  export default {
    computed: {
      style() {
        return {
          fill: '#0000f0',
        }
      }
    }
  };
</script>
