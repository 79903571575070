<template>
  <svg width="100%" :height="height" class="lines-graph">
    <g class="axis">
      <g class="x-axis">
        <line class="base-line" :x1="margins.left" x2="732px" :y1="height-margins.bottom"
              :y2="height-margins.bottom"></line>
        <g class="tick" v-for="tick in graph.xAxis" :transform="tick.transform">
          <line v-bind="tick.line"></line>
          <text transform="translate(-10,30)">{{tick.label}}</text>
        </g>
      </g>
      <g class="y-axis">
        <line class="base-line" :x1="margins.left" :x2="margins.left" :y1="0" :y2="height - 5"></line>
        <g class="tick" v-for="tick in graph.yAxis" :transform="tick.transform">
          <line v-bind="tick.line"></line>
          <text transform="translate(-26, 6)">{{tick.label}}</text>
        </g>
      </g>
    </g>
    <g class="content">
      <g class="line" v-for="(line,idx) in graph.lines">
        <path :d="line.d" :class="line.class"></path>
        <text :transform="line.transform">{{lineDesc[idx+1].label}}</text>
      </g>
      <g class="line current">
        <path :d="graph.current.d" class="line line-current"></path>
        <rect :transform="graph.current.transform" :x="-6" :y="-20" :width="margins.right" height="30" rx="6" ry="6"></rect>
        <text :transform="graph.current.transform">{{lineDesc[0].label}}</text>
      </g>
    </g>
    <rect class="lines-graph__indicator-bg lines-graph__indicator-bg-x" transform="translate(-6, 5)"></rect>
    <text class="lines-graph__indicator" transform="translate(-6, 10)">%</text>
    <!--<rect class="lines-graph__indicator-bg lines-graph__indicator-bg-y" transform="translate(46, 310)"></rect>-->
    <text class="lines-graph__indicator" transform="translate(740, 305)">Age</text>
  </svg>
</template>

<script>
  import * as d3 from 'd3';

  export default {
    props: {
      lines: {
        type: Array,
      },
      lineDesc: {
        type: Object,
      },
      years: {
        type: Array,
      }
    },
    data() {
      return {
        width: 800,
        height: 293,
        margins: {
          top: 10,
          bottom: 20,
          left: 30,
          right: 100,
        }
      }
    },
    computed: {
      graph() {
        const vm = this;
        const {width, height, margins} = vm;
        const xScale = d3.scaleLinear().domain([vm.years[0], vm.years[vm.years.length - 1]]).range([margins.left, width - margins.right]).clamp(true);
        const yScale = d3.scaleLinear().domain([vm.lines[1][0], vm.lines[3][vm.lines[4].length - 1]]).range([height - margins.bottom, margins.top]).clamp(true);
        const lineFn = d3.line().x((i, idx) => xScale(vm.years[idx])).y((i) => yScale(i)).curve(d3.curveCardinal);
        const lines = vm.lines.slice(0, 4).map((i, idx) => {
          return {
            transform: `translate(${width - margins.right + 6}, ${yScale(i[i.length - 1]) + 6})`,
            class: `line line-${idx}`,
            d: lineFn(i)
          }
        });

        const res = {
          xAxis: vm.years.map(i => {
            return {
              transform: `translate(${xScale(i)},${height - margins.bottom})`,
              label: i,
              line: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 10,
              }
            }
          }),
          yAxis: yScale.ticks(5).map(i => {
            return {
              label: i,
              transform: `translate(20, ${yScale(i)})`,
              line: {
                x0: 0,
                x1: 10,
                y0: 0,
                y1: 0,
              }
            }
          }),
          lines: lines.slice(1, 4),
          current: lines[0],
        };

        //console.log(res);

        return res;
      }
    }
  }
</script>
