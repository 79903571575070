import Vue from 'vue/dist/vue.esm.js';
import VueI18n from 'vue-i18n';
import routes from './routes';

Vue.use(VueI18n);

import './assets/css/style.css';

import GeneticGraph from './components/GeneticRiskGraph.vue';
import LinesGraph from './components/LinesGraph.vue';
import ErrorPage from './pages/404.vue';
import VLink from './components/VLink.vue';

Vue.component('genetic-risk-graph', GeneticGraph);
Vue.component('lines-graph', LinesGraph);
Vue.component('v-link', VLink);

const app = new Vue({
  i18n: new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
  }),
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      const matchingView = routes[this.currentRoute];
      console.log('currentRoute',this.currentRoute, '|  matchingView', matchingView);
      return matchingView ? matchingView : ErrorPage;
    }
  },
  render (h) { return h(this.ViewComponent); }
}).$mount('#app');

window.addEventListener('popstate', () => {
  app.currentRoute = window.location.pathname;
});
