<template>
    <div>
        living
    </div>
</template>

<script>
  export default {
    beforeCreate: function() {
      document.body.className = 'smartest-security-living';
    }
  };
</script>
