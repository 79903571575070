<template>
  <div class="grid grid--no-gutter">
    <div class="grid__col-md-5">
      <div class="data-selection">
        <div class="data-selection__label">If your genetic risk is...</div>
        <div class="data-selection__group">
          <input type="range" min="-3" max="3" step=".2" class="range-selector range-selector--big" v-model="input.grs"/>
          <div class="grid grid--no-gutter">
            <div class="grid__col-equal text-left">
              <span class="range-indicator">Low</span>
            </div>
            <div class="grid__col-equal text-center">
              <span class="range-indicator">Medium</span>
            </div>
            <div class="grid__col-equal text-right">
              <span class="range-indicator">High</span>
            </div>
          </div>
        </div>
        <div class="data-selection__label data-selection__label--padded">... and personal parameters are:</div>
        <div class="data-selection__group">
          <div class="data-selection__group-item">
            <div class="grid grid--no-gutter">
              <div class="grid__col-md-3 content-center">
                <label class="gender-label">Gender:</label>
              </div>
              <div class="grid__col-md-9 text-center">
                <input type="radio" id="GenderM" class="hide gender-radio" value="M" v-model="input.gender"/>
                <label class="gender-selector" for="GenderM">Male</label>
                <input type="radio" id="GenderF" class="hide gender-radio" value="F" v-model="input.gender"/>
                <label class="gender-selector" for="GenderF">Female</label>
              </div>
            </div>
          </div>
          <div class="data-selection__group-item">
            <div class="grid grid--no-gutter">
              <div class="grid__col-md-3 content-center">
                <label class="range-label">Age:</label>
              </div>
              <div class="grid__col-md-9">
                <div class="range-output">{{ input.age }}
                  <span class="range-output__unit">years</span>
                </div>
                <div class="range-wrap">
                  <div class="range-btn range-btn--minus" v-on:click="count(input, 'dec', 'age', {min: 18, max: 100})"></div>
                  <input type="range" min="18" max="100" step="1" data-stat="age" class="range-selector range-selector--small" v-model="input.age"/>
                  <div class="range-btn range-btn--plus" v-on:click="count(input, 'inc', 'age', {min: 18, max: 100})"></div>
                </div>
                <div class="range-indicators">
                  <div class="range-indicator range-indicator--alt range-indicator--max">100</div>
                  <div class="range-indicator range-indicator--alt range-indicator--min">18</div>
                </div>
              </div>
            </div>
          </div>
          <div class="data-selection__group-item">
           <div class="grid grid--no-gutter">
              <div class="grid__col-md-3 content-center">
                <label class="range-label">Weight:</label>
              </div>
              <div class="grid__col-md-9">
                <div class="range-output">{{ input.weight }}
                  <span class="range-output__unit">kg</span>
                </div>
                <div class="range-wrap">
                  <div class="range-btn range-btn--minus" v-on:click="count(input, 'dec', 'weight', {min: 40, max: 160})"></div>
                  <input type="range" min="40" max="160" step="1" class="range-selector range-selector--small" v-model="input.weight"/>
                  <div class="range-btn range-btn--plus" v-on:click="count(input, 'inc', 'weight', {min: 40, max: 160})"></div>
                </div>
                <div class="range-indicators">
                  <div class="range-indicator range-indicator--alt range-indicator--max">160</div>
                  <div class="range-indicator range-indicator--alt range-indicator--min">40</div>
                </div>
              </div>
            </div>
          </div>
          <div class="data-selection__group-item">
           <div class="grid grid--no-gutter">
              <div class="grid__col-md-3 content-center">
                <label class="range-label">Height:</label>
              </div>
              <div class="grid__col-md-9">
                <div class="range-output">{{ input.height }}
                  <span class="range-output__unit">cm</span>
                </div>
                <div class="range-wrap">
                  <div class="range-btn range-btn--minus" v-on:click="count(input, 'dec', 'height', {min: 100, max: 220})"></div>
                  <input type="range" min="100" max="220" step="1" class="range-selector range-selector--small" v-model="input.height"/>
                  <div class="range-btn range-btn--plus" v-on:click="count(input, 'inc', 'height', {min: 100, max: 220})"></div>
                </div>
                <div class="range-indicators">
                  <div class="range-indicator range-indicator--alt range-indicator--max">220</div>
                  <div class="range-indicator range-indicator--alt range-indicator--min">100</div>
                </div>
              </div>
            </div>
          </div>
          <div class="data-selection__group-item">
           <div class="grid grid--no-gutter">
              <div class="grid__col-md-3 content-center">
                <label class="range-label">Waist:</label>
              </div>
              <div class="grid__col-md-9">
                <div class="range-output">{{ input.waist }}
                  <span class="range-output__unit">cm</span>
                </div>
                <div class="range-wrap">
                  <div class="range-btn range-btn--minus" v-on:click="count(input, 'dec', 'waist', {min: 50, max: 160})"></div>
                  <input type="range" min="50" max="160" step="1" class="range-selector range-selector--small" v-model="input.waist"/>
                  <div class="range-btn range-btn--plus" v-on:click="count(input, 'inc', 'waist', {min: 50, max: 160})"></div>
                </div>
                <div class="range-indicators">
                  <div class="range-indicator range-indicator--alt range-indicator--max">160</div>
                  <div class="range-indicator range-indicator--alt range-indicator--min">50</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-selection__label data-selection__label--alt">Existing conditions:</div>
        <div class="data-selection__group data-selection__group--columns">
          <div class="data-selection__group-item">
            <div class="checkbox-label">Myocardial Infarction (MI)</div>
            <div class="checkbox">
              <input type="checkbox" id="checkbox-mi" :true-value="1" :false-value="0" v-model="input.hasMi"/>
              <label for="checkbox-mi" class="checkbox__selector"></label>
            </div>
          </div>
          <div class="data-selection__group-item">
            <div class="checkbox-label">Hypertension (HYP)</div>
            <div class="checkbox">
              <input type="checkbox" id="checkbox-hyp" :true-value="1" :false-value="0" v-model="input.hasHyp"/>
              <label for="checkbox-hyp" class="checkbox__selector"></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid__col-md-7">
      <div class="data-report">
        <div class="data-report__label">{{$t('t2d.title')}}</div>
        <p class="text" style="text-align:justify">{{$t('t2d.introduction')}}</p>
        <div class="data-report__label data-report__label--alt">{{$t('t2d.genetic.heading')}}</div>

        <p class="text">{{$t('t2d.genetic.description')}} <span class="text text--red">{{result.grsText}}</span>.</p>
        <div class="data-report__circles" style="padding-left: 3px">
          <genetic-risk-graph :group="result.grsGroup"></genetic-risk-graph>
        </div>

        <div class="data-report__label data-report__label--alt" style="margin-top: 0">{{$t('t2d.lifestyle.heading')}}</div>
        <p class="text">
          {{$t('t2d.lifestyle.description')}} <span class="text text--red">{{result.envText}}</span>.
        </p>
        <div class="data-report__label data-report__label--alt">{{$t('t2d.chart.heading')}}</div>
        <p class="text">{{$t('t2d.chart.description')}}</p>

        <div class="data-report__graph" style="padding-left: 60px" v-if="result.graph">
          <lines-graph v-bind="result.graph"></lines-graph>
        </div>
       <div class="graph-error" v-else>
          <div class="graph-error__inner">Data is not available for over 70 years old</div>
        </div>
        <p class="text text--tight">
          {{$t('t2d.general.description')}}
          <span class="text text--red">{{result.envText}}</span>.
        </p>
        <p class="text text--tight" v-html="$t('t2d.general.result', description)"></p>
        <div class="nav nav--right">
          <v-link href="/" class="nav__button">Go back</v-link>
          <span v-on:click="showModal" class="nav__button nav__button--blue v-modal-open">View full demo report</span>
        </div>

        <!--<div class="" v-if="recommendation.isLow">-->
          <!--<p class="">{{$t('t2d.recommendation.low.title')}}</p>-->
          <!--<ul>-->
            <!--<li v-for="l in $t('t2d.recommendation.low.items')">{{l}}</li>-->
          <!--</ul>-->
        <!--</div>-->
        <!--<div class="" v-if="recommendation.isAverage">-->
          <!--<p class="">{{$t('t2d.recommendation.average.title')}}</p>-->
          <!--<ul>-->
            <!--<li v-for="l in $t('t2d.recommendation.average.items')">{{l}}</li>-->
          <!--</ul>-->
        <!--</div>-->
        <!--<div class="" v-if="recommendation.isHigh">-->
          <!--<p class="">{{$t('t2d.recommendation.high.title')}}</p>-->
          <!--<ul>-->
            <!--<li v-for="l in $t('t2d.recommendation.high.items')">{{l}}</li>-->
          <!--</ul>-->
        <!--</div>-->

      <modal v-show="isModalVisible" @close="closeModal"/>
      </div>
    </div>
  </div>
</template>

<script>

  import risk from '../t2d_calculation.js';
  import modal from '../components/Modal.vue';

  export const messages = {
    en: {
      t2d: {
        title: 'Type 2 diabetes mellitus',
        introduction: `
          Diabetes mellitus type 2 (also known as type 2 diabetes) is a long-term metabolic disorder that is characterized by high blood sugar, insulin resistance, and its relative insufficiency. Type 2 diabetes is largely related to increased body weight and lack of exercise. Its prevalence also increases with age. Additionally, some people are genetically more predisposed than others. Thereby, weight management and regular physical activity are the key actions required to the manage the diabetes risk, especially in high genetic risk group.
        `,
        genetic: {
          heading: `Genetic predisposition`,
          description: `Your genetic risk to get type 2 diabetes is`,
        },
        lifestyle: {
          heading: `Non-genetic risk`,
          description: `Considering your measurements and health status, your non-genetic risk for type 2 diabetes is`,
        },
        chart: {
          heading: `Total risk`,
          description: `Total type 2 diabetes risk estimate depending on BMI and age considering your genetic and non-genetic risk combined`,
        },
        general: {
          description: 'According to your current body weight and body constitution the probability that you will get type 2 diabetes is',
          result: `
          The probability that you will get the type 2 diabetes in the next 10 years is <span class="text text--red">{p10Year}%</span> and <span class="text text--red">{pRefYear}%</span> by the age of <span class="text text--red">{refYear}</span>.
          `,
        },
        recommendation: {
          low: {
            title: 'Tähtis on diabeediriski madalal hoidmine; milleks on olulised:',
            items: [
              `tervislik toitumine;`,
              `igapäevane aktiivne liikumine;`,
              `regulaarne kehakaalu kontroll.`,
            ],
          },
          average: {
            title: 'Tähtis on diabeediriski madalal või keskmisel tasemel hoidmine; milleks on olulised:',
            items: [
              `tervislik toitumine;`,
              `igapäevane aktiivne liikumine (soovitatavalt vähemalt 30 min päevas);`,
              `regulaarne kehakaalu kontroll, ülekaalu vältimine või vähendamine.`,
            ],
          },
          high: {
            title: 'Riski alandamiseks on soovitav:',
            items: [
              `tervislik toitumine ja toidukoguste jälgimine;`,
              `igapäevane aktiivne liikumine (vähemalt 30 min) ja regulaarselt muu mõõdukal tasemel füüsiline koormus;`,
              `regulaarne kehakaalu jälgimine (ülekaalu vältimine või vähendamine,
                vajadusel kaaluda toitumisnõustaja konsultatsiooni);`,
              `suitsetamisest ja liigsest alkoholitarbimisest hoidumine;`,
              `kaaluda diabeediriski hindamist perearsti või muu tervishoiutöötaja poolt.`,
            ],
          },
        },
      },
    },
  };

  export default {
    name: 't2d',
    i18n: {messages},
    components: {
      modal
    },
    beforeCreate() {
        document.body.className = 'app-page';
    },
    data() {
      return {
        isModalVisible: false,
        input: {
          gender: 'M',
          age: 30,
          height: 171,
          weight: 77,
          waist: 82,
          grs: -1.1,
          hasHyp: 0,
          hasMi: 0,
        },
        diabetes: {
          grsGroup: 5,
          grsText: 'average',
        },
        diabetesCalculation: {
          isSet: false,
          envRisk: {
            text: 'NA',
          }
        },
        result: {
          grsGroup: 1,
          grsText: 'average',
          envText: 'average',
          graph: null,
        }
      };
    },
    computed: {
      recommendation() {
        const vm = this;
        if (!vm.result.graph) {
          return {};
        }
        if (vm.result.envText) {
          return {
            isLow: vm.result.envText === 'low',
            isAverage: vm.result.envText === 'heightened',
            isHigh: vm.result.envText === 'high' || vm.result.envText === 'very high',
          };
        }
        if (vm.result.grsText) {
          return {
            isLow: vm.result.grsText === 'low',
            isAverage: vm.result.grsText === 'heightened',
            isHigh: vm.result.grsText === 'high',
          };
        }
      },
      description() {
        const {result} = this;
        return {p10Year: result.totalRisk10yP, refYear: '70', pRefYear: result.totalRisk70P}
      },
      descriptionClasses() {
        const vm = this;
        if (!vm.result.graph) {
          return {};
        }
        // TODO: KME implement coloring based on description.
        return {
          alert: true,
          'alert-success': vm.result.envText === 'low',
          'alert-warning': vm.result.envText === 'heightened',
          'alert-danger': vm.result.envText === 'high' || vm.result.envText === 'very high',
        }
      }
    },
    mounted() {
      this.calculate(this.input);
    },
    methods: {
      calculate(n) {
        this.result = risk(n.gender, +n.age, n.weight, n.height, n.waist, n.hasHyp, n.hasMi, +n.grs);
        console.log("risk:", risk(n.gender, +n.age, n.weight, n.height, n.waist, n.hasHyp, n.hasMi, +n.grs))
      },
      count(input, action, key, range) {
        input[key] = Number(input[key]);

        if (action === 'inc' && input[key] < range.max) {
          input[key] += 1;
        } else if (action === 'dec' && input[key] >= range.min) {
          input[key] -= 1;
        }
      },
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      }
    },
    watch: {
      input: {
        deep: true,
        handler(n, o) {
          this.calculate(n);
        },
      },
    }
  }
</script>
